import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { WebService } from '../web.service';

import { DialogComponent } from '../shared/dialog/dialog.component';

@Component({
  selector: 'app-web-vouchers',
  templateUrl: 'web-vouchers.component.html',
  styleUrl: 'web-vouchers.component.css'
})
export class WebVouchersComponent implements OnInit {
  @ViewChild(DialogComponent) dialog: DialogComponent;
  private ws = WebService.getInstance();
  protected loggedIn: boolean = false;
  
  constructor(private router: Router) {}

  public ngOnInit(): void {
    const payload = this.ws.getTokenPayload();
    this.loggedIn = payload != null;
  }
}
