export class User {
  private _name: string;
  private _lastname: string;
  private _email: string;
  private _phone: string;
  private _registered: boolean
  
  constructor(json: any[]) {
    this._name = json['name']
    this._lastname = json['lastname'];
    this._email = json['email'];
    this._phone = json['phone'];
    this._registered = json['registered'];
  }

  // Instance public methods
  get name(): string {
    return this._name;
  }

  get lastname(): string {
    return this._lastname;
  }

  get email(): string {
    return this._email;
  }

  get phone(): string {
    return this._phone;
  }

  get fullname(): string {
    return this._name + ' ' + this.lastname;
  }

  get isRegistered(): boolean {
    return this._registered;
  }
}