import { BehaviorSubject, Subscription } from 'rxjs';
import { WebEndpoint, LoginRequest, RegisterRequest, GetUserReservesRequest, RenewPasswordRequest, UpdatePasswordRequest,
  DeleteAccountRequest, GetDailyOccupationRequest, GetHourlyOccupationRequest, RequestReserveRequest, CancelReserveRequest, CheckCodeRequest, ConfirmReserveRequest, 
  GetReserveRequest,  AssignReserveRequest, UpdateUserRequest} from './web.endpoint';
import { DailyOccupation, HourlyOccupation, Reserve } from '../../../tpv/src/app/reserves/_data/_data'; // TODO: import this through shared folder
import { Zone } from '../../../tpv/src/app/stores/_data/zones';

export class WebService {
  private static instance: WebService;
  private endpoint: WebEndpoint;
  private translations: Map<string, string> = new Map();
  private zonesSource = new BehaviorSubject<Zone[]>([]);

  private lang: string = "es";

  private constructor() {
    console.log('initiating reserves service');
    this.endpoint = new WebEndpoint();
    this.reset();
  }

  public static getInstance(): WebService {
    if (!WebService.instance) {
      WebService.instance = new WebService();
    }
    return WebService.instance;
  }
  
  private reset() {
    this.translations.clear();
    this.getTranslations();
    this.endpoint.getZones(r => this.zonesSource.next(r.zones));
  }

  public setLang(lang: string) {
    this.lang = lang;
    this.endpoint.setLang(lang);
    this.reset();
  }

  // A tag translation may contain optional params and mandatory params
  // Optional params are like >> [opt_param:text that may include {any_param}] << and will get into the result text if the param is defined
  // Mandatory params are like >> {parm} <<, and will get replaced with the provided value associated to the param (note: unlike server version, formatting is done by the caller)
  public translate(tag: string, params: any = {}): string {
    let translation = this.translations.get(tag);
    if (translation == undefined) {
      return tag;
    }
    let match : string[] = [];
    // replace optional params first
    const optionalRegex = /(^|[^\\])(\[(\w+):(([^\]]|\\\])+)\])/; // will match [param:text] where starting '[' or ending ']' can be escaped with '\'
    while ((match = translation.match(optionalRegex)) != null) {
      translation = translation.replace(match[2], params[match[3]] ? match[4] : "");
    }
    // now replace mandatory params (will include values within previous optional replacements)
    const requiredRegex = /(^|[^\\])(\{(\w+)\})/;
    while ((match = translation.match(requiredRegex)) != null) {
      const param = params[match[3]];
      if (param == undefined) {
        return tag;
      }
      translation = translation.replace(match[2], param);
    }
    return translation;
  }

  private getTranslations() {
    this.endpoint.getTranslations(r => this.translations = r.translations, _ => console.error("An error occurred while retrieving translations"));
  }

  public subscribeToToken(onTokenUpdated: (token: any) => void) {
    return this.endpoint.subscribeToToken(onTokenUpdated);
  }

  public subscribeToZones(onZonesUpdated: (zones: Zone[]) => void): Subscription {
    return this.zonesSource.asObservable().subscribe(z => onZonesUpdated(z));
  }

  public clearToken() {
    this.endpoint.clearActiveToken();
  }

  public getTokenPayload(): any {
    return this.endpoint.getActiveTokenPayload(); 
  }

  public isLoggedIn(): boolean {
    return this.endpoint.getActiveTokenPayload() != null;
  }

  public login(email:string, password: string, onSuccess: () => void, onError: (message: string) => void = null) {
    this.endpoint.login(new LoginRequest(email, password), 
      r => { this.endpoint.storeToken(r.auth_token, ['id']); onSuccess(); },
      (_, m) => onError ? onError(m) : null);
  }

  public register(email:string, name:string, lastname: string, phone: string, onSuccess: () => void, onError: () => void = null) {
    this.endpoint.register(new RegisterRequest(email, name, lastname, phone), () => onSuccess(), _ => onError ? onError() : null);
  }

  public checkCode(code: string, onSuccess: () => void, onError: () => void = null) {
    this.endpoint.checkCode(new CheckCodeRequest(code), () => onSuccess(), _ => onError ? onError() : null);
  }

  public renewPassword(email: string, onSuccess: () => void, onError: () => void = null) {
    this.endpoint.renewPassword(new RenewPasswordRequest(email), () => onSuccess(), _ => onError ? onError() : null);
  }

  public updatePassword(code: string, newPassword: string, onSuccess: () => void, onError: () => void = null) {
    this.endpoint.updatePassword(new UpdatePasswordRequest(code, newPassword), () => onSuccess(), _ => onError ? onError() : null);
  }

  public updateUser(name:string, lastname: string, phone: string, onSuccess: () => void, onError: () => void = null) {
    this.endpoint.updateUser(new UpdateUserRequest(name, lastname, phone), 
      r => { this.endpoint.storeToken(r.auth_token, ['id']); onSuccess(); },
      _ => onError ? onError() : null);
  }

  public deleteAccount(onSuccess: () => void, onError: () => void) {
    this.endpoint.deleteAccount(new DeleteAccountRequest(), () => onSuccess(), _ => onError ? onError() : null);
  }

  public getDailyOccupation(zoneId: number, since: string, until: string, onSuccess: (occupation: DailyOccupation) => void) {
    this.endpoint.getDailyOccupation(new GetDailyOccupationRequest(zoneId, since, until), r => onSuccess(r.occupation));
  }

  public getHourlyOccupation(zoneId: number, date: string, replaceCode: string, onSuccess: (occupation: HourlyOccupation) => void) {
    this.endpoint.getHourlyOccupation(new GetHourlyOccupationRequest(this.lang, zoneId, date, replaceCode), r => onSuccess(r.occupation));
  }

  public requestReserve(zoneId: number, date: string, start: number, duration: number, size: number, attributes: string, urlCode: string,
      onSuccess: (codes: string[]) => void, onError: () => void = null) {
    this.endpoint.reserveRequest(new RequestReserveRequest(zoneId, date, start, duration, size, attributes, urlCode), r => onSuccess(r.codes), _e => onError ? onError() : null);
  }

  public getReserve(code: string, version: string, onSuccess: (reserve: Reserve) => void, onError: (code: number, message: string) => void = null) {
    this.endpoint.getReserve(new GetReserveRequest(code, version), r => onSuccess(r.reserve), onError);
  } 

  public getUserReserves(page: number, filter: string, onSuccess: (reserves: Reserve[], count: number, pageSize: number) => void, onError: () => void = null) {
    this.endpoint.getReserves(new GetUserReservesRequest(page, filter), r => onSuccess(r.reserves, r.count, r.pageSize), _e => onError ? onError() : null);
  }

  public assignReserve(code: string, onSuccess: (r: Reserve) => void, onError: () => void = null) {
    this.endpoint.assignReserve(new AssignReserveRequest(code), r => onSuccess(r.reserve), _e => onError ? onError() : null);
  }

  public confirmReserve(code: string, onSuccess: (r: Reserve) => void, onError: () => void = null) {
    this.endpoint.confirmReserve(new ConfirmReserveRequest(code), r => onSuccess(r.reserve), _e => onError ? onError() : null);
  }

  public cancelReserve(code: string, version: number|string, onSuccess: (r: Reserve) => void, onError: () => void = null) {
    this.endpoint.cancelReserve(new CancelReserveRequest(code, version), r => onSuccess(r.reserve), _e => onError ? onError() : null);
  }

  public s() {
    this.endpoint.s();
  }

  public t(i, t) {
    this.endpoint.t(i, t);
  }
}
