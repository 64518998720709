import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lbt-input',
  templateUrl: './lbt-input.component.html',
  styleUrl: './lbt-input.component.css',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LbtInputComponent), multi: true}],
  host: {'[class.error]': 'error', '[class.disabled]': 'disabled', '[class.focused]': 'focused', '[class.empty]': '!input'}
})
export class LbtInputComponent implements ControlValueAccessor {
  @Input() protected disabled = false;
  @Input() protected type: string;
  @Input() protected hint: string;
  @Input() protected placeholder: string;
  @Input() protected error: boolean;
  @Input() protected errorMessage: string;
  @Output() focus = new EventEmitter();
  @Output() focusout = new EventEmitter();
  protected focused = false;

  protected onFocusInternal(focus: boolean) {
    if (focus != this.focused) {
      this.focused = focus;
      focus ? this.focus.emit() : this.focusout.emit();
    }
  }

  protected scrollTop(event) {
    let target = event.target || event.srcElement || event.currentTarget;
    target.scrollTop = "0";
  }

  protected onKeyUpInternal(event) {
    let target = event.target || event.srcElement || event.currentTarget;
    target.style.height = "1em";
    target.style.maxHeight = "0";
    target.style.height = target.scrollHeight + "px";
    target.style.maxHeight = target.scrollHeight + "px";
  }

  /**** input var ****/
  private _input = '';
  protected set input(value: string) {
    if (this._input !== value) {
      this._input = value;
      this.onChange(value);
      this.onTouch(value);
    }
  }

  protected get input(): string {
    return this._input;
  }

  /**** stregth var  ****/
  private _strength: number = null;
  @Input() public set strength(value: number) {
    if (this._strength !== value) {
      this._strength = value;
    }
  }

  public get strength(): number {
    return this._strength;
  }

  /**** ngModel ****/
  protected onChange: any = () => {};
  protected onTouch: any = () => {};

  public writeValue(value: any) {
    this._input = value;
  }
  
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
