@if (loggedIn == false || code != null) {
  <app-web-login></app-web-login>
}
@else {
  <nav class="navbar">
  <span (click)="mode=Mode.User" [class.selected]="mode==Mode.User">{{ getTranslation('USER_US_PROFILE') }}</span>
  <span (click)="mode=Mode.Reserves" [class.selected]="mode==Mode.Reserves">{{ getTranslation('USER_US_RESERVES') }}</span>
  <!--span (click)="mode=Mode.Vouchers" [class.selected]="mode==Mode.Vouchers">{{ getTranslation('USER_US_VOUCHERS') }}</span-->
</nav>

<div class="header" [class.selected]="mode == Mode.User">{{ getTranslation('USER_US_PROFILE') }}</div>
<app-web-user class="block" [class.selected]="mode == Mode.User"></app-web-user>
<div class="header" [class.selected]="mode == Mode.Reserves">{{ getTranslation('USER_US_RESERVES') }}</div>
<app-web-reserves class="block" [class.selected]="mode == Mode.Reserves"></app-web-reserves>
<!--div class="header" [class.selected]="mode == Mode.Vouchers">{{ getTranslation('USER_US_VOUCHERS') }}</div>
<app-web-vouchers class="block" [class.selected]="mode == Mode.Vouchers"></app-web-vouchers-->
}