<div class="keys">
@for (key of keys; track key) {
  <lbt-button class="number"
      (click)="onKey(key)">
    {{ key }}
  </lbt-button>
}
  <lbt-button class="clear"
      (click)="clearForm()">
    C
  </lbt-button>
</div>
<span class="errorMessage">{{errorMessage}}</span>

