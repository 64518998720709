import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { RosterPipe, IntegerPipe, MultiplierPipe, PercentPipe, EuroPipe, CountdownPipe, TimePipe, TimeOfDayPipe, CloseDatePipe, ElapsedPipe, SafeHtmlPipe } from './pipe/pipes';
import { DialogComponent } from './dialog/dialog.component';
import { LbtInputComponent } from './forms/lbt-input/lbt-input.component';
import { LbtButtonComponent } from './forms/lbt-button/lbt-button.component';
import { LbtCheckboxComponent } from './forms/lbt-checkbox/lbt-checkbox.component';
import { LbtSelectComponent } from './forms/lbt-select/lbt-select.component';
import { LbtPinComponent } from './forms/lbt-pin/lbt-pin.component';
import { LbtPhoneComponent } from './forms/lbt-phone/lbt-phone.component';

@NgModule({
  declarations: [
    RosterPipe,
    IntegerPipe,
    MultiplierPipe,
    PercentPipe,
    EuroPipe,
    CountdownPipe,
    TimePipe,
    TimeOfDayPipe,
    CloseDatePipe,
    ElapsedPipe,
    SafeHtmlPipe,
    DialogComponent,
    LbtInputComponent,
    LbtButtonComponent,
    LbtCheckboxComponent,
    LbtSelectComponent,
    LbtPinComponent,
    LbtPhoneComponent,
  ],
  imports: [
    CommonModule,
    FormsModule,
  ],
  exports: [
    RosterPipe,
    IntegerPipe,
    MultiplierPipe,
    PercentPipe,
    EuroPipe,
    CountdownPipe,
    TimePipe,
    TimeOfDayPipe,
    CloseDatePipe,
    ElapsedPipe,
    SafeHtmlPipe,
    DialogComponent,
    LbtInputComponent,
    LbtButtonComponent,
    LbtCheckboxComponent,
    LbtSelectComponent,
    LbtPinComponent,
    LbtPhoneComponent,
  ]
})
export class SharedModule { }
