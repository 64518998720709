export class IdItem {
  private _id: number;
  
  constructor (id: number) {
    this._id = id;
  }

  public get id(): number {
    return this._id;
  }

  public morph(idNew: number): void {
    this._id = idNew;
  }
}