import { IdItem } from '../../shared/selectable/idItem';
import { DateUtils } from '../../shared/utils/dateUtils';


export enum WaitingStatus {
  Waiting,
  Accepted,
  Seated,
  Rejected,
  Unanswered,
  Due,
  Unknown
}

export class Waiting extends IdItem {
  private _added: Date;
  private _pax: number;
  private _name: string;
  private _phone: string;
  private _until: number;
  private _status: string;
  private _ticketId: number;

  constructor(json: any[]) {
    super(+json['id']);
    this._added = DateUtils.parseMysqlDate(json['added']);
    this._pax = +json['pax'];
    this._name = json['name'];
    this._phone = json['phone'];
    this._until = +json['until'];
    this._status = json['status'];
    this._ticketId = json['ticket_id'];
  }

  // Instance public methods
  get added(): Date {
    return this._added;
  }

  get addedTime(): string {
    // yyyy-mm-dd HH:MM:SS
    // ^0         ^11  ^16
    return this._added.toLocaleString('sv').substring(11, 16);
  }

  get isToday(): boolean {
    return DateUtils.date2IsoString(this._added) == DateUtils.getTodayString();
  }

  get pax(): number {
    return this._pax;
  }

  get name(): string {
    return this._name;
  }

  get phone(): string {
    return this._phone;
  }

  get until(): number {
    return this._until;
  }

  get status(): WaitingStatus {
    if (this._ticketId != null) {
      return WaitingStatus.Seated;
    }
    const until = new Date(this.added);
    until.setHours(Math.floor(this.until), Math.floor((this.until * 60) % 60), 0);
    const due = new Date().toLocaleString('sv') > until.toLocaleString('sv');

    switch (this._status) {
    case 'waiting':
      return due ? WaitingStatus.Due : WaitingStatus.Waiting;
    case 'accepted':
      return WaitingStatus.Accepted;
    case 'assigned':
    case 'seated':
      return WaitingStatus.Seated;
    case 'rejected':
      return WaitingStatus.Rejected;
    case 'unanswered':
      return due ? WaitingStatus.Due : WaitingStatus.Unanswered;
    default:
      return WaitingStatus.Unknown;
    }
  }

  get lastStatus(): WaitingStatus {
    if (this._ticketId != null) {
      return WaitingStatus.Seated;
    }
    
    switch (this._status) {
    case 'waiting':
      return WaitingStatus.Waiting;
    case 'accepted':
      return WaitingStatus.Accepted;
    case 'assigned':
    case 'seated':
      return WaitingStatus.Seated;
    case 'rejected':
      return WaitingStatus.Rejected;
    case 'unanswered':
      return WaitingStatus.Unanswered;
    default:
      return WaitingStatus.Unknown;
    }
  }

  get ticketId(): number {
    return this._ticketId;
  }
}

export class WaitingMap extends Map<number, Waiting> {
  constructor(waitings: Waiting[] = []) {
    super(waitings.map(w => [w.id, w]));
  } 
};