<span class="placeholder">{{placeholder}}</span>
<select [disabled]="disabled"
    [(ngModel)]="selected"
    (focus)="onFocusInternal(true)"
    (focusout)="onFocusInternal(false)" >
  <option class="disabled" [ngValue]="null" disabled="true">{{hint}}</option>
@for (value of values; track value.id) {
  <option [ngValue]="value.id" [class.disabled]="value.id == null" [disabled]="value.id == null">{{value.text}}</option>
}
</select>
<div class="underline"
    [class.active]="selected != null">
  <span class="active"></span>
</div>