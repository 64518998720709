import { Component, AfterViewInit, ViewChild } from '@angular/core';
import { DialogComponent } from './shared/dialog/dialog.component';
import { WebService } from './web.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  host: {'[class.loggedIn]': 'loggedIn'}
})
export class AppComponent implements AfterViewInit {
  @ViewChild(DialogComponent) dialog: DialogComponent;
  private ws = WebService.getInstance();

  public ngAfterViewInit() {
    DialogComponent.registerDialog(this.dialog);
    
    window.addEventListener("message", this.onMessage.bind(this), false);

    // Create a resize observer and observe body. Message parent about our current height
    new ResizeObserver(
      entries => {
        const body = document.body;
        const html = document.documentElement;
        const height = Math.max( body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight );
        window.parent.postMessage({'height': height}, "*")
      }
    ).observe(document.body);
  }

  private onMessage(event: any) {
    // We don´t check event.origin, as this would be any client, and the worst effect from a malicious actor would be to change the lang, which is OK
    if (event.data.lang) {
      console.log("switching to " + event.data.lang);
      this.ws.setLang(event.data.lang);
    }
  }
}
