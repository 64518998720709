@if (loggedIn) {
<div class="user">
  <span class="title">{{ getTranslation('USER_RA_USER_DATA') }}</span>
  <lbt-input [disabled]="true" placeholder="{{getTranslation('USER_US_INPUT_NAME')}}" [(ngModel)]="name" [error]="name != '' && !nameValid()" errorMessage="{{getTranslation('USER_US_INPUT_NAME_ERR')}}"></lbt-input>
  <lbt-input [disabled]="true" placeholder="{{getTranslation('USER_US_INPUT_LASTNAME')}}" [(ngModel)]="lastname" [error]="lastname != '' && !lastnameValid()" errorMessage="{{getTranslation('USER_US_INPUT_LASTNAME_ERR')}}"></lbt-input>
  <lbt-input [disabled]="true" placeholder="{{getTranslation('USER_US_INPUT_EMAIL')}}" [(ngModel)]="email" [error]="email != '' && !emailValid()" errorMessage="{{getTranslation('USER_US_INPUT_EMAIL_ERR')}}"></lbt-input>
  <lbt-phone [disabled]="true" placeholder="{{getTranslation('USER_US_INPUT_PHONE')}}" [(ngModel)]="phone" [prefixRegex]="prefixRegex" (error)="phoneError = $event" (validLengths)="validLengths = $event" errorMessage="{{getTranslation('USER_US_INPUT_PHONE_ERR', {'validLengths': validLengths})}}"></lbt-phone>
</div>
}

<div class="zones" [class.ok]="isZoneValid()">
  <span class="title">{{ getTranslation('USER_RA_ZONE') }}</span>
@for (zone of zones; track zone.id) {
  <div [class]="'value zone_' + zone.id"
      [class.disabled]="!isZoneActive(zone)"
      [class.selected]="isZoneSelected(zone)"
      (click)="selectedZone=zone">
    <span class="name">{{ zone.name }}</span>
    <span class="desc">{{ zone.desc }}</span>
  </div>
}
</div>

<div class="reserve" [class.hidden]="!isZoneValid()" [class.ok]="isReserveDataValid()">
  <span class="title">{{ getTranslation('USER_RA_DATA') }}</span>
  <lbt-select [disabled]="selectedZone==null" placeholder="{{getTranslation('USER_RA_INPUT_DATE')}}" [(ngModel)]="date" [values]="dateValues"></lbt-select>
  <lbt-select [disabled]="date==null" placeholder="{{getTranslation('USER_RA_INPUT_PAX')}}" [(ngModel)]="size" [values]="sizeValues"></lbt-select>
  <lbt-select [disabled]="size==null" placeholder="{{getTranslation('USER_RA_INPUT_TIME_IN')}}" [(ngModel)]="start" [values]="startValues"></lbt-select>
  <lbt-select [disabled]="start==null" placeholder="{{getTranslation('USER_RA_INPUT_TIME_OUT')}}" [(ngModel)]="duration" [values]="durationValues"></lbt-select>
  <lbt-input [disabled]="duration==null" class="comments" placeholder="{{getTranslation('USER_RA_INPUT_COMMENTS')}}" type="multiline" [(ngModel)]="comments"></lbt-input>
  <span class="notes" [innerHtml]="getTranslation('USER_RA_DATA_NOTES')"></span>
</div>

<div class="options" [class.hidden]="!isReserveDataValid()" [class.ok]="areOptionsValid()">
  <span class="title">{{ getTranslation('USER_RA_OPTIONS') }}</span>
@for (option of getOptions(); track option) {
  <div [class]="'option ' + option.getTag()"
      [class.required]="option.isRequired()">
    <span class="subtitle">{{ option.getName() }}</span>
@for (value of option.getValues(); track value) {
    <div [class]="'value ' + value.getValue() + ' ' + getValueStatus(option, value)"
        [class.disabled]="!isValueActive(value)"
        [class.selected]="isValueSelected(option, value)"
        (click)="selectOptionValue(option, value)">
      <span class="name">{{ value.getName() }}</span>
      <span class="desc">{{ value.getDescription() }}</span>
@if (value.getMinTotalPax() > 0) {
      <span class="note tiny">{{getTranslation('USER_RA_OPTIONS_MIN_PAX')}} {{value.getMinTotalPax() | euro}}</span>
}
    </div>
}
  </div>
} @empty {
  <span>{{ getTranslation('USER_RA_OPTIONS_NONE') }}</span>
}
@if (getOptions().length > 0) {
  <span class="notes" [innerHtml]="getTranslation('USER_RA_OPTIONS_NOTES')"></span>
}
</div>

@if (minTotalPax > 0) {
<div class="minTotal" [class.hidden]="!isReserveDataValid() || !areOptionsValid()" [class.ok]="isMinTotalAccepted()">
  <span class="title">{{ getTranslation('USER_RA_MIN_TOTAL_TITLE') }}</span>
  <lbt-checkbox class="note" [(ngModel)]="minTotalAccepted">{{getTranslation('USER_RA_MIN_TOTAL_MSG', {"minTotal": getMinTotal() | euro, "minTotalPax": minTotalPax | euro, "size": size})}}</lbt-checkbox>
</div>
}

@if (!loggedIn) {
<div class="user" [class.hidden]="!isReserveDataValid() || !areOptionsValid() || !isMinTotalAccepted()" [class.ok]="isUserDataValid()">
  <span class="title">{{ getTranslation('USER_RA_USER_DATA') }}</span>
  <lbt-input placeholder="{{getTranslation('USER_US_INPUT_NAME')}}" [(ngModel)]="name" [error]="name != '' && !nameValid()" errorMessage="{{getTranslation('USER_US_INPUT_NAME_ERR')}}"></lbt-input>
  <lbt-input placeholder="{{getTranslation('USER_US_INPUT_LASTNAME')}}" [(ngModel)]="lastname" [error]="lastname != '' && !lastnameValid()" errorMessage="{{getTranslation('USER_US_INPUT_LASTNAME_ERR')}}"></lbt-input>
  <lbt-input placeholder="{{getTranslation('USER_US_INPUT_EMAIL')}}" [(ngModel)]="email" [error]="email != '' && !emailValid()" errorMessage="{{getTranslation('USER_US_INPUT_EMAIL_ERR')}}"></lbt-input>
  <lbt-phone placeholder="{{getTranslation('USER_US_INPUT_PHONE')}}" [(ngModel)]="phone" [prefixRegex]="prefixRegex" (error)="phoneError = $event" (validLengths)="validLengths = $event" errorMessage="{{getTranslation('USER_US_INPUT_PHONE_ERR', {'validLengths': validLengths})}}"></lbt-phone>
  <span class="register" [innerHtml]="getTranslation('USER_RA_USER_REGISTER')" (click)="onInnerHtmlClick($event)"></span>
</div>
}

<div class="send">
@if (loggedIn && !isUserDataValid()) {
  <span class="bad_data" [innerHtml]="getTranslation('USER_RA_BAD_CONTACT_DATA')" (click)="onInnerHtmlClick($event)"></span>
}
@else if (!isValid()) {
  <span class="invalid" [innerHtml]="getTranslation('USER_RA_INVALID')"></span>
}
  <lbt-button [disabled]="!isValid()" (click)="requestReserve()">{{ urlCode ? this.getTranslation('USER_RA_UPDATE') : this.getTranslation('USER_RA_REQUEST')}}</lbt-button>
</div>
