@if (dialogShow) {
<div class="box {{dialogStyle}}">
  <span class="title">{{ dialogTitle }}</span>
  <span class="message" [innerHtml]="dialogMessage | safeHtml"></span>
@for (input of dialogInputs; track input) {
@if (getDialogInput(input); as i) {
  <lbt-input
    [class]="i.name"
    placeholder="{{i.name}}"
    [(ngModel)]="i.value"
    [error]="i.value != '' && !i.isValid()"
    errorMessage="{{i.error}}">
  </lbt-input>
}
@if (getDialogOption(input); as o) {
  <lbt-select 
    placeholder="{{o.name}}"
    [(ngModel)]="o.selected" 
    [values]="o.values">
  </lbt-select>
}
}
  <lbt-button
      [disabled]="!isValid()"
      (click)="closeDialog(true)">ACEPTAR</lbt-button>
@if (!isInfo) {
  <lbt-button
      (click)="closeDialog(false)">CANCELAR</lbt-button>
}
</div>
}