<div class="new">
  <div class="title">{{getTranslation('USER_RL_NEW')}}</div>
  <span>Puedes solicitar una nueva reserva usando <a (click)="goToReserve()">nuestro formulario</a>.<br>Recuerda estar siempre logueado para disfrutar de las ventajas de usuarios registrados: Mayor plazo de reserva y reservas auto-confirmadas.</span>
</div>

@if (nextReserves?.length > 0) {
<div class="next">
  <div class="title">{{getTranslation('USER_RL_NEXT')}}</div>
  <app-web-reserve-card 
      [reserve]="nextReserves[0]"
      [warning]="showWarning(nextReserves[0]) ? getTranslation('USER_RL_WARNING') : null"
      [expanded]="null"
      (refresh)="reload()">
  </app-web-reserve-card>
</div>
}

<div class="reserves">
  <div class="title">{{getTranslation('USER_RL_FUTURE')}}</div>
@for (reserve of nextReserves; track reserve.code; let i = $index) {
  <app-web-reserve-card 
      [reserve]="reserve"
      [warning]="showWarning(reserve) ? getTranslation('USER_RL_WARNING') : null"
      [expanded]="reserve.code == selectedReserveCode"
      (toggle)="selectReserve(reserve)"
      (refresh)="reload()">
  </app-web-reserve-card>
}
@empty {
  <span class="empty">{{getTranslation('USER_RL_FUTURE_NONE')}}</span>
}
  <div class="pager">
@for (page of getPageList(true); track page) {
    <lbt-button [class.outlined]="page != currentNextPage.toString()" (click)="getPage(page, true)">{{page}}</lbt-button>
}
  </div>
</div>

<div class="reserves prev">
  <div class="title">{{getTranslation('USER_RL_OTHER')}}</div>
@for (reserve of prevReserves; track reserve.code; let i = $index) {
    <app-web-reserve-card 
        [reserve]="reserve"
        [expanded]="reserve.code == selectedReserveCode"
        (toggle)="selectReserve(reserve)"
        (refresh)="reload()">
    </app-web-reserve-card>
}
@empty {
    <span class="empty">{{getTranslation('USER_RL_OTHER_NONE')}}</span>
}
    <div class="pager">
@for (page of getPageList(false); track page) {
      <lbt-button [class.outlined]="page != currentPrevPage.toString()" (click)="getPage(page, false)">{{page}}</lbt-button>
}
    </div>
</div>