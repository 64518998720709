import { BehaviorSubject, Subscription } from 'rxjs';
import { Base64Url } from '../utils/base64Utils';

class Tokens {
  token: string[];
  active: number;
  previous: number;
}

export class JWT {
  private key: string;
  private tokens: Tokens;
  private activeToken: string;
  private activeTokenSource = new BehaviorSubject<String>(null);

  public constructor(key: string) {
    this.key = key || 'token';
    try {
      this.load();
    } catch {
      // Invalid storaged tokens > remove
      this.clear();
    }
    if (this.tokens.active >= 0 && this.tokens.active < this.tokens.token.length) {
      this.activeToken = this.tokens.token[this.tokens.active];
      this.activeTokenSource.next(this.getActiveTokenPayload());
    }
  }

  public subscribeToToken(onTokenUpdated: (token: any) => void): Subscription {
     return this.activeTokenSource.asObservable().subscribe(t => onTokenUpdated(t));
  }

  public hasTokens(): boolean {
    return this.tokens.token.length > 0;
  }

  public setActiveToken(index: number): boolean {
    if (index >= 0 && index < this.tokens.token.length) {
      this.tokens.previous = this.tokens.active;
      this.tokens.active = index;
      this.save();
      this.activeToken = this.tokens.token[this.tokens.active];
      this.activeTokenSource.next(this.getActiveTokenPayload());
      return true;
    }
    return false;
  }

  public getActiveToken(): string {
    return this.activeToken;
  }

  public getActiveTokenHeader(): any {
    return JWT.getTokenHeader(this.activeToken);
  }

  public getActiveTokenPayload(): any {
    return JWT.getTokenPayload(this.activeToken);
  }

  public getActiveTokenSignature(): any {
    return JWT.getTokenSignature(this.activeToken);
  }

  public getTokensPayloads(): any[] {
    let payloads: any[] = [];
    for (let token of this.tokens.token) {
      payloads.push(JWT.getTokenPayload(token));
    }
    return payloads;
  }

  public storeToken(token: string, idPath: string[] = ['user', 'uid']): boolean {
    let updated = false;
    if (JWT.isTokenValid(token)) {
      const tokenId = JWT.getPathFromTokenPayload(token, idPath);
      for (const i in this.tokens.token) {
        if (tokenId === JWT.getPathFromTokenPayload(this.tokens.token[i], idPath)) {
          // Token with same idPath founded, update and select it.
          this.tokens.token[i] = token;
          if (this.tokens.active !== +i) {
            this.tokens.previous = this.tokens.active;
          }
          this.tokens.active = +i;
          this.activeToken = token;
          updated = true;
        }
      }
      if (updated === false) {
        // New token, add and select it
        this.tokens.token.push(token)
        this.tokens.previous = this.tokens.active;
        this.tokens.active = this.tokens.token.length - 1;
        this.activeToken = token;
        updated = true;
      }
    }
    this.save();
    this.activeTokenSource.next(this.getActiveTokenPayload());
    return updated;
  }

  public clearActiveToken() {
    this.tokens.token.splice(this.tokens.active, 1);
    this.tokens.active = this.tokens.previous >= this.tokens.active ? this.tokens.previous - 1 : this.tokens.previous;
    if (this.tokens.active < 0 || this.tokens.active >= this.tokens.token.length) {
      this.tokens.active = 0;
    }
    this.save();
    this.activeToken = this.tokens.token[this.tokens.active];
    this.activeTokenSource.next(this.getActiveTokenPayload());
  }

  private load() {
    const tokens = JSON.parse(localStorage.getItem(this.key));
    this.tokens = tokens != null ? tokens : {token: [], active: 0, previous: 0};
  }

  private save() {
    localStorage.removeItem(this.key);
    localStorage.setItem(this.key, JSON.stringify(this.tokens));
  }

  private clear() {
    localStorage.removeItem(this.key);
    this.tokens = {token: [], active: 0, previous: 0};
  }

  private static isTokenValid(token: string): boolean {
    // Check token is a string in the form base64.base64.base64
    return /^[a-zA-Z0-9\-_]+\.[a-zA-Z0-9\-_]+\.[a-zA-Z0-9\-_]+$/.test(token);
  }

  private static getTokenHeader(token: string): any {
    if (JWT.isTokenValid(token)) {
      const header = Base64Url.utf8Decode(token.split(".")[0]);
      try {
        return JSON.parse(header);
      }
      catch (e) {
        console.log("Can't parse token header: " + header + " due to " + e.message);
      }
    }
    return null;
  }

  private static getTokenPayload(token: string): any {
    if (JWT.isTokenValid(token)) {
      const payload = Base64Url.utf8Decode(token.split(".")[1]);
      try {
        return JSON.parse(payload);
      }
      catch (e) {
        console.log("Can't parse token payload: " + payload + " due to " + e.message);
      }
    }
    return null;
  }

  private static getTokenSignature(token: string): any {
    if (JWT.isTokenValid(token)) {
      return token.split(".")[2];
    }
    return null;
  }

  private static getPathFromTokenPayload(token: string, path: string[]): any {
    let id = JWT.getTokenPayload(token);
    for (let dir of path) {
      if (id != null) {
        id = id[dir];
      }
    }
    return id;
  }
}
