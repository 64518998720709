@if (loggedIn == false) {
  <span class="header">{{getTranslation('USER_US_PROFILE')}}</span>
}
<div class="user">
  <qrcode class="qrcode" [qrdata]="qrCode" [width]="256" [errorCorrectionLevel]="'M'"></qrcode>
  <div class="data">
    <lbt-input [disabled]="true" placeholder="{{getTranslation('USER_US_INPUT_EMAIL')}}" [(ngModel)]="email"></lbt-input>
    <lbt-input [disabled]="edit == false" placeholder="{{getTranslation('USER_US_INPUT_NAME')}}" [(ngModel)]="name" [error]="name != '' && !nameValid()" errorMessage="{{getTranslation('USER_US_INPUT_NAME_ERR')}}"></lbt-input>
    <lbt-input [disabled]="edit == false" placeholder="{{getTranslation('USER_US_INPUT_LASTNAME')}}" [(ngModel)]="lastname" [error]="lastname != '' && !lastnameValid()" errorMessage="{{getTranslation('USER_US_INPUT_LASTNAME_ERR')}}"></lbt-input>
    <lbt-phone [disabled]="edit == false" placeholder="{{getTranslation('USER_US_INPUT_PHONE')}}" [(ngModel)]="phone" [prefixRegex]="prefixRegex" (error)="phoneError = $event" (validLengths)="validLengths = $event" errorMessage="{{getTranslation('USER_US_INPUT_PHONE_ERR', {'validLengths': validLengths})}}"></lbt-phone>
    <div class="buttons">
      <lbt-button class="delete outlined" (click)="deleteAccount()">{{getTranslation('USER_US_BUTTON_DELETE')}}</lbt-button>
      <lbt-button [disabled]="edit == true && isUserDataValid() == false" [class.outlined]="edit == false" (click)="toggleEdit()">{{getTranslation('USER_US_BUTTON_EDIT')}}</lbt-button>
      <lbt-button (click)="logout()">{{getTranslation('USER_US_BUTTON_EXIT')}}</lbt-button>
    </div>
  </div>
</div>
