<!-- div class="header">{{ getTranslation('USER_US_HEADER') }}</div>
<div class="intro" [innerHtml]="getTranslation('USER_US_INTRO')"></div -->

@switch (mode) {
@case (Mode.Login) {
<div class="box">
  <span class="title">{{getTranslation('USER_US_WELCOME')}}</span>
  <lbt-input placeholder="{{getTranslation('USER_US_INPUT_EMAIL')}}" [(ngModel)]="email" [error]="email != '' && !emailValid()" errorMessage="{{getTranslation('USER_US_INPUT_EMAIL_ERR')}}"></lbt-input>
  <lbt-input type="password" placeholder="{{getTranslation('USER_US_INPUT_PASSWORD')}}" [(ngModel)]="password"></lbt-input>
  <!-- lbt-checkbox [(ngModel)]="accept">Recuérdame en este dispositivo.</lbt-checkbox -->
  <lbt-button class="outlined" (click)="mode = Mode.Forgot">{{getTranslation('USER_US_PASSWORD_FORGOT')}}</lbt-button>
  <lbt-button [disabled]="!emailValid() || password == ''" (click)="login()">{{getTranslation('USER_US_LOGIN')}}</lbt-button>
  <span (click)="mode = Mode.Register"><a>{{getTranslation('USER_US_NEW')}}</a></span>
</div>
}
@case (Mode.Register) {
<div class="box">
  <span class="title">{{getTranslation('USER_US_REGISTER')}}</span>
  <lbt-input placeholder="{{getTranslation('USER_US_INPUT_NAME')}}" [(ngModel)]="name" [error]="name != '' && !nameValid()" errorMessage="{{getTranslation('USER_US_INPUT_NAME_ERR')}}"></lbt-input>
  <lbt-input placeholder="{{getTranslation('USER_US_INPUT_LASTNAME')}}" [(ngModel)]="lastname" [error]="lastname != '' && !lastnameValid()" errorMessage="{{getTranslation('USER_US_INPUT_LASTNAME_ERR')}}"></lbt-input>
  <lbt-phone placeholder="{{getTranslation('USER_US_INPUT_PHONE')}}" [(ngModel)]="phone" [prefixRegex]="prefixRegex" (error)="phoneError = $event" (validLengths)="validLengths = $event" errorMessage="{{getTranslation('USER_US_INPUT_PHONE_ERR', {'validLengths': validLengths})}}"></lbt-phone>
  <lbt-input placeholder="{{getTranslation('USER_US_INPUT_EMAIL')}}" [(ngModel)]="email" [error]="email != '' && !emailValid()" errorMessage="{{getTranslation('USER_US_INPUT_EMAIL_ERR')}}"></lbt-input>
  <lbt-checkbox [(ngModel)]="accept">Acepto los <a (click)="showTermsAndConditions($event)">términos y condiciones</a>.</lbt-checkbox>
  <lbt-button class="outlined" (click)="mode = Mode.Login">{{getTranslation('USER_US_BACK')}}</lbt-button>
  <lbt-button [disabled]="!nameValid() || !lastnameValid() || !phoneValid() || !emailValid() || !accept" (click)="register()">{{getTranslation('USER_US_REGISTER')}}</lbt-button>
</div>
}
@case (Mode.Password) {
<div class="box">
  <span class="title">{{getTranslation('USER_US_NEW_PASSWORD')}}</span>
  <lbt-input type="password" placeholder="{{getTranslation('USER_US_INPUT_PASSWORD')}}" [(ngModel)]="password" [strength]="score" [error]="password != '' && score < 3" errorMessage="{{passwordError}}" (ngModelChange)="passwordUpdated()"></lbt-input>
  <lbt-input type="password" placeholder="{{getTranslation('USER_US_INPUT_PASSWORD2')}}" [(ngModel)]="password2"  [error]="password2 != '' && password2 != password" errorMessage="{{getTranslation('USER_US_INPUT_PASSWORD2_ERR')}}"></lbt-input>
  <lbt-button class="outlined" (click)="help()">{{getTranslation('USER_US_HELP')}}</lbt-button>
  <lbt-button [disabled]="!passwordsValid()" (click)="updatePassword()">{{getTranslation('USER_US_SEND')}}</lbt-button>
</div>
}
@case (Mode.Forgot) {
<div class="box">
  <span class="title">{{getTranslation('USER_US_FORGOT_TITLE')}}</span>
  <span>{{getTranslation('USER_US_FORGOT_MSG')}}</span>
  <lbt-input placeholder="{{getTranslation('USER_US_INPUT_EMAIL')}}" [(ngModel)]="email" [error]="email != '' && !emailValid()" errorMessage="{{getTranslation('USER_US_INPUT_EMAIL_ERR')}}"></lbt-input>  
  <lbt-button class="outlined" (click)="mode = Mode.Login">{{getTranslation('USER_US_BACK')}}</lbt-button>
  <lbt-button [disabled]="!emailValid()" (click)="renewPassword()">{{getTranslation('USER_US_SEND')}}</lbt-button>
</div>
}
@case (Mode.Migrating) {
  <div class="header">{{ getTranslation('USER_US_MIGRATING_TITLE') }}</div>
  <span>{{getTranslation('USER_US_MIGRATING_MSG')}}</span>
}
}