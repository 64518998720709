import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { WebService } from '../web.service';

@Component({
  selector: 'app-reserve-layout',
  templateUrl: './reserve.layout.html',
  styleUrl: './reserve.layout.css'
})

export class ReserveLayout implements OnInit {
  private ws = WebService.getInstance();
  protected command: string;
  protected urlCode: string;
  
  constructor(private route: ActivatedRoute) {}

  public ngOnInit(): void {
    // url param access
    this.route.queryParams.subscribe(
      params => { 
        const keys = Object.keys(params);
        if (keys.length == 1) {
          this.command = keys[0][0];
          this.urlCode = keys[0].substring(1);
        }
      }
    );
    // url path access
    const command = this.route.snapshot.paramMap.get('command');
    const code = this.route.snapshot.paramMap.get('code');
    if (command && code) {
      this.command = command;
      this.urlCode = code;
    }
  }

  protected getTranslation(tag: string): string {
    return this.ws.translate(tag);
  }
}
