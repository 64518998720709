import { zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core'

export class ZxcvbnResult {
  score: number;
  message: string;
}

export class Zxcvbn {

  private static loadOptions = async () => {
    const zxcvbnCommonPackage = await import(/* webpackChunkName: "zxcvbnCommonPackage" */ '@zxcvbn-ts/language-common');
    const zxcvbnEnPackage = await import(/* webpackChunkName: "zxcvbnEnPackage" */ '@zxcvbn-ts/language-en');
    const zxcvbnEsPackage = await import(/* webpackChunkName: "zxcvbnEsPackage" */ '@zxcvbn-ts/language-es-es');
  
    return {
      dictionary: {
        ...zxcvbnCommonPackage.dictionary,
        ...zxcvbnEnPackage.dictionary,
        ...zxcvbnEsPackage.dictionary,
      },
      graphs: zxcvbnCommonPackage.adjacencyGraphs,
      translations: zxcvbnEsPackage.translations,
    }
  }
  
  public static checkPassword = async (password: string): Promise<ZxcvbnResult> => {
    const options = await Zxcvbn.loadOptions();
    zxcvbnOptions.setOptions(options);
    const results = zxcvbn(password);
    return {score: results.score, message: results.feedback.warning ? results.feedback.warning : results.feedback.suggestions[0]};
  }
}