import { BehaviorSubject, Subscription } from 'rxjs';

import { IdItem } from "./idItem";

export abstract class DatedItem extends IdItem {

  constructor(id: number) {
    super(id);
  }

  public abstract getDate(): string; // Returns ISO date associated to the Item
}

class CacheEntry<Item extends DatedItem> {
  lastAccess = 0;
  ids = new Set<number>();
  source = new BehaviorSubject<Item[]>([]);
}

export class DailyCache<Item extends DatedItem> {
  private size: number; // max days in the map
  private cache = new Map<string, CacheEntry<Item>>();
  private map = new Map<number, Item>();

  constructor(size: number = 10) {
    this.size = size;
  }

  public clear() {
    this.cache.forEach(c => { c.source.next([]); c.source.unsubscribe();});
    this.cache.clear();
  }

  public getDate(date: string): Item[]|null {
    const cacheEntry = this.cache.get(date);
    if (cacheEntry != undefined) {
      return Array.from(cacheEntry.ids).map(id => this.map.get(id)!);  // Non-null Assertion Operator (Postfix !)
    }
    return null;
  }

  public subscribeToDate(date: string, onItemsUpdated: (items: Item[]) => void): Subscription {
    if (!this.cache.has(date)) {
      this.updateDate(date, []);
    }
    return this.cache.get(date)!.source.subscribe(onItemsUpdated);
  }
  
  public updateDate(date: string, items: Item[]) {
    let entry = this.cache.get(date);
    if (entry != undefined) {
      // update entry
      entry.lastAccess = new Date().getUTCMilliseconds() / 1000;
      entry.ids = new Set(items.map(i => i.id));
    }
    else {
      // remove oldest entry if already on limit
      if (this.cache.size >= this.size) {
        let oldest = Number.MAX_VALUE;
        let remove = "";
        this.cache.forEach((v, k) => {
          if (v.lastAccess < oldest && v.source.observers.length == 0) {
            oldest = v.lastAccess;
            remove = k;
          }
        });
        // remove reserves
        if (remove) {
          this.cache.get(remove)!.ids.forEach(id => this.map.delete(id));
          this.cache.delete(remove);
        }
      }
      // add new entry
      entry = new CacheEntry<Item>();
      entry.lastAccess = new Date().getUTCMilliseconds() / 1000;
      entry.ids = new Set(items.map(r => r.id));
      this.cache.set(date, entry);
    }
    // fill/update the map
    items.forEach(i => this.map.set(i.id, i));
    // signal subscribers
    entry.source.next(items);
  }

  public getItem(id: number): Item|undefined {
    return this.map.get(id);
  }

  public updateItem(item: Item): void {
    // check if item's date has changed
    const prev = this.map.get(item.id);
    if (prev != undefined && prev.getDate() != item.getDate()) {
      // remove it from previous cache entry
      const prevEntry = this.cache.get(prev.getDate());
      if (prevEntry != undefined) {
        this.map.delete(item.id);
        prevEntry.ids.delete(item.id);
        prevEntry.source.next(Array.from(prevEntry.ids).map(id => this.map.get(id)!))
      }
    }
    // find new cache entry for item
    const entry = this.cache.get(item.getDate());
    if (entry != undefined) {
      // add item to it
      this.map.set(item.id, item);
      entry.ids.add(item.id);
      entry.source.next(Array.from(entry.ids).map(id => this.map.get(id)!));
    }
  }

  public findItems(condition: (item: Item) => boolean): Item[] {
    return Array.from(this.map.values()).filter(condition);
  }
}