import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'lbt-checkbox',
  templateUrl: './lbt-checkbox.component.html',
  styleUrl: './lbt-checkbox.component.css',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LbtCheckboxComponent), multi: true}],
  host: {
    '[class.error]': 'error',
    '[class.disabled]': 'disabled',
    '[class.focused]':'focused',
    '[class.selected]': 'selected',
    '[tabindex]': 'disabled ? -1 : 0',
    '(click)': 'onClickInternal()',
    '(focus)': 'onFocusInternal(true)',
    '(focusout)': 'onFocusInternal(false)',
    '(blur)': 'onFocusInternal(false)',
    '(document:keyup.space)': 'onClickInternal()',
    '(document:keyup.enter)': 'onClickInternal()',
  }
})
export class LbtCheckboxComponent implements ControlValueAccessor {
  @Input() public disabled = false;
  protected focused = false;
  @Output() focus = new EventEmitter();
  @Output() focusout = new EventEmitter();

  protected onClickInternal() {
    if (this.disabled == false) {
      this.selected = !this.selected;
    }
  }

  protected onFocusInternal(focus: boolean) {
    if (focus != this.focused) {
      this.focused = focus;
      focus ? this.focus.emit() : this.focusout.emit();
    }
  }

  /**** input var ****/
  private _selected = false;
  protected set selected(value: boolean) {
    if (this._selected !== value) {
      this._selected = value;
      this.onChange(value);
      this.onTouch(value);
    }
  }

  protected get selected(): boolean {
    return this._selected;
  }

  /**** ngModel ****/
  protected onChange: any = () => {};
  protected onTouch: any = () => {};

  public writeValue(value: any) {
    this.selected = value;
  }
  
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
