import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';


export class LbtSelectValue {
  public id: number|string|null;
  public text: string;

  constructor(id: number|string|null, text: string|null = null) {
    this.id = id;
    this.text = text != null ? text : id != null ? id.toString() : '';
  }
}

@Component({
  selector: 'lbt-select',
  templateUrl: './lbt-select.component.html',
  styleUrl: './lbt-select.component.css',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LbtSelectComponent), multi: true}],
  host: {'[class.disabled]': 'disabled', '[class.focused]': 'focused', '[class.empty]': 'selected!=null'}
})
export class LbtSelectComponent implements ControlValueAccessor {
  @Input() protected hint: string;
  @Input() protected placeholder: string;
  @Input() protected disabled: boolean;
  @Input() protected values: LbtSelectValue[];
  @Output() focus = new EventEmitter();
  @Output() focusout = new EventEmitter();
  protected focused = false;

  // TODO: use input placeholder as hint

  constructor() {}

  public onFocusInternal(focus: boolean) {
    this.focused = focus;
    focus ? this.focus.emit() : this.focusout.emit();
  }

  /**** input var ****/
  private _selected: number|null = null;
  set selected(selected: number) {
    if (this._selected !== selected) {
      this._selected = selected;
      this.onChange(selected);
      this.onTouch(selected);
    }
  }

  get selected(): number|null {
    return this._selected;
  }

  /**** ngModel ****/
  onChange: any = () => {};
  onTouch: any = () => {};

  writeValue(value: any) {
    this._selected = value;
  }
  
  registerOnChange(fn: any) {
    this.onChange = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
