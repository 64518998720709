import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Zxcvbn } from '../shared/zxcvbn/zxcvbn';
import { WebService } from '../web.service';

import { DialogComponent } from '../shared/dialog/dialog.component';

enum Mode {
  Login,
  Register,
  Password,
  Forgot,
  Migrating,
}

@Component({
  selector: 'app-web-login',
  templateUrl: './web-login.component.html',
  styleUrl: './web-login.component.css'
})
export class WebLoginComponent implements OnInit {
  private ws = WebService.getInstance();
  protected mode: Mode;
  protected Mode = Mode;
  private code: string;

  constructor(private router: Router, private route: ActivatedRoute) {
    this.mode = Mode.Login; //document.location.hostname == 'u.six.es' ? Mode.Migrating : Mode.Login;
  }
  
  public ngOnInit(): void {
     // url param access
     this.route.queryParams.subscribe(
      params => { 
        const keys = Object.keys(params);
        if (keys.length == 1) {
          const code = keys[0];
          this.processCode(code);
        }
      }
    );
    // url path access
    const code = this.route.snapshot.paramMap.get('code');
    if (code != null) {
      this.processCode(code);
    }
    else if (this.ws.isLoggedIn()) {
      this.router.navigate(['user']);
    }
  }

  private processCode(code: string) {
    console.log('processing code ' + code);
    if (code != null && code != '') {
      this.mode = Mode.Password;
      this.ws.checkCode(code, () => this.code = code, () => DialogComponent.showError(this.getTranslation('USER_US_CODE_INVALID_ERR')).then(_ => this.router.navigate([''])));
    }
    else if (this.ws.isLoggedIn()) {
      this.router.navigate(['user']);
    }
  }

  protected name: string = '';
  private nameRegex = /.{3,25}/;
  protected nameValid(): boolean {
    return (this.name.trim().match(this.nameRegex)) ? true : false;
  };

  protected lastname: string = '';
  private lastnameRegex = /.{3,50}/;
  protected lastnameValid(): boolean {
    return (this.lastname.trim().match(this.lastnameRegex)) ? true : false;
  };

  protected email: string = '';
  private emailRegex = /[-\w\.]+@([-\w]+\.)+[-\w]{2,5}/;
  protected emailValid(): boolean {
    return (this.email.trim().match(this.emailRegex)) ? true : false;
  };

  protected prefixRegex = /^[34]\d+$/; // European prefix only
  protected phone: string = '+34';     // Default to Spain prefix
  protected phoneError: boolean = false;
  protected validLengths: string = '';
  protected phoneValid(): boolean {
    return !this.phoneError;
  };
  
  protected password = '';
  protected passwordError = '';
  protected score: number = 0;
  protected password2 = '';
  protected passwordsValid() : boolean {
    return this.password != '' && this.password == this.password2 && this.score > 2;
  }

  protected accept = false;
  
  protected login() {
    this.ws.login(this.email, this.password, 
      () => this.router.navigate(['user']),
      msg => DialogComponent.showError(msg ? msg : this.getTranslation('USER_US_USER_INVALID_ERR'))
    );
    // Do not reset form, if success, this component will be unloaded, and if error, let the user edit it.
  }

  protected register() {
    this.ws.register(this.email, this.name, this.lastname, this.phone.replace(/[^\+\d]/g, ''),
      () => DialogComponent.showInfoDialog(this.getTranslation('USER_US_REGISTER_TITLE'), this.getTranslation('USER_US_REGISTER_MSG'))
          .then(_ => this.reset()),
      () => DialogComponent.showError(this.getTranslation('USER_US_UNKNOWN_ERR'))
          .then(_ => this.reset())
    );
  }

  protected renewPassword() {
    this.ws.renewPassword(this.email,
      () => DialogComponent.showInfoDialog(this.getTranslation('USER_US_USER_PASSWORD_UPDATE_TITLE'), this.getTranslation('USER_US_USER_PASSWORD_UPDATE_MSG'))
          .then(_ => this.reset()),
      () => DialogComponent.showError(this.getTranslation('USER_US_UNKNOWN_ERR'))
          .then(_ => this.reset())
    );
  }

  protected updatePassword() {
    this.ws.updatePassword(this.code, this.password,
      () => DialogComponent.showInfoDialog(this.getTranslation('USER_US_USER_PASSWORD_UPDATED_TITLE'), this.getTranslation('USER_US_USER_PASSWORD_UPDATED_MSG'))
          .then(_ => this.reset()),
      () => DialogComponent.showError(this.getTranslation('USER_US_UNKNOWN_ERR'))
          .then(_ => this.reset())
    );
  }

  protected help() {
    // TODO: show help
  }

  protected passwordUpdated() {
    Zxcvbn.checkPassword(this.password).then(
      result => { 
        this.score = result.score;
        switch (this.score) {
        case 0:
        case 1:
          this.passwordError = this.getTranslation('USER_US_PASSWORD_VERY_WEAK') + ' - ' + result.message;
          break;
        case 2:
          this.passwordError = this.getTranslation('USER_US_PASSWORD_WEAK') + ' - ' + result.message;
          break;
        default:
          this.passwordError = '';
          break;
        }
      }
    );
  }

  protected showTermsAndConditions(event: Event) {
    event.stopPropagation();
    DialogComponent.showInfoDialog(this.ws.translate('USER_TERMS_AND_CONDITIONS_TITLE'), this.ws.translate('USER_TERMS_AND_CONDITIONS_DETAIL'), 'big');
  }

  private reset() {
    this.mode = Mode.Login;
    this.code = '';
    this.email = '';
    this.name = '';
    this.lastname = '';
    this.phone = '';
    this.password = '';
    this.password2 = '';
    this.router.navigate(['user']);
  }

  protected getTranslation(tag: string, params: any = {}): string {
    return this.ws.translate(tag, params);
  }
}
