import { Component, forwardRef, Input, Output, EventEmitter } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LbtSelectValue } from '../lbt-select/lbt-select.component';
import data from './international_codes.json';

function getFlagIcon(countryCode: string): string {
  return String.fromCodePoint(countryCode[0].charCodeAt(0) + 127397, countryCode[1].charCodeAt(0) + 127397);
}

@Component({
  selector: 'lbt-phone',
  templateUrl: './lbt-phone.component.html',
  styleUrl: './lbt-phone.component.css',
  providers: [{provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => LbtPhoneComponent), multi: true}],
  host: {'[class.error]': 'error', '[class.disabled]': 'disabled', '[class.focused]': 'focused', '[class.empty]': '!input'}
})
export class LbtPhoneComponent implements ControlValueAccessor {
  @Input() protected disabled = false;
  @Input() protected type: string;
  @Input() protected hint: string;
  @Input() protected placeholder: string;
  @Input() protected errorMessage: string;
  @Input() protected set prefixRegex(regex: RegExp) {
    this.countryValues = data.countries.filter(c => regex == null || c.prefix.match(regex) != null).sort((c1, c2) => c1.prefix.localeCompare(c2.prefix)).map(c => new LbtSelectValue('+' + c.prefix, getFlagIcon(c.code) + '+' + c.prefix));
  };
  @Output() private validLengths = new EventEmitter<string>();
  @Output() private error = new EventEmitter<boolean>();
  @Output() focus = new EventEmitter();
  @Output() focusout = new EventEmitter();
  protected focused = false;

  protected onFocusInternal(focus: boolean) {
    if (focus != this.focused) {
      this.focused = focus;
      focus ? this.focus.emit() : this.focusout.emit();
    }
  }

  protected scrollTop(event) {
    let target = event.target || event.srcElement || event.currentTarget;
    target.scrollTop = "0";
  }

  protected onKeyUpInternal(event) {
    let target = event.target || event.srcElement || event.currentTarget;
    target.style.height = "1em";
    target.style.maxHeight = "0";
    target.style.height = target.scrollHeight + "px";
    target.style.maxHeight = target.scrollHeight + "px";
  }

  /**** input var ****/
  protected set input(value: string|null) {
    value = value ? value.trim() : '';
    let country = '';
    if (value.length > 0 && value[0] == '+') {
      let countryValue = this.countryValues.find(v => value.startsWith(v.id? v.id.toString() : '-'));
      if (countryValue != undefined) {
        country = countryValue.id ? countryValue.id.toString() : '';
      }
    }
    let phone = value.substring(country.length);
    if (this._country !== country || this._phone !== phone) {
      this.country = country;
      this.phone = phone;
      this.onChange(value);
      this.onTouch(value);
      this.error.emit(!this.phoneValid());
    }
  }

  protected get input(): string {
    return this.country + this.phone;
  }

  /*** pass down vars ***/

  private _country: string = '';
  protected set country(country: string) {
    if (country != this._country) {
      this._country = country;
      const countryData = data.countries.find(c => c.prefix == country.substring(1));
      const lengths: number[] = countryData ? countryData.lengths : [5,12];
      this.phoneRegex = new RegExp("^([ \-\(\)]*[0-9]){" + lengths[0] + "," + lengths[lengths.length-1] + "}$");
      this.validLengths.emit(lengths.join(', ')); // TODO: convert this to interval and list format: for instance => 7-10, 12
      this.onChange(this._country + this._phone);
      this.onTouch(this._country + this._phone);
      this.error.emit(!this.phoneValid());
    }
  }
  protected get country(): string {
    return this._country;
  }
  protected countryValues: LbtSelectValue[];
  
  private _phone: string = '';
  protected set phone(phone: string) {
    if (this._phone !== phone) {
      this._phone = phone;
      this.onChange(this._country + this._phone);
      this.onTouch(this._country + this._phone);
      this.error.emit(!this.phoneValid());
    }
  }
  protected get phone(): string {
    return this._phone;
  }
  protected phoneRegex: RegExp;

  protected phoneValid(): boolean {
    return this._phone.trim().match(this.phoneRegex) != null;
  };

  /**** ngModel ****/
  protected onChange: any = () => {};
  protected onTouch: any = () => {};

  public writeValue(value: any) {
    this.input = value;
  }
  
  public registerOnChange(fn: any) {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any) {
    this.onTouch = fn;
  }
}
