import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { WebService } from '../web.service';

import { DialogComponent, DialogOption, OptionValue } from '../shared/dialog/dialog.component';
import { LbtSelectValue } from '../shared/forms/lbt-select/lbt-select.component';

@Component({
  selector: 'app-web-user',
  templateUrl: 'web-user.component.html',
  styleUrl: 'web-user.component.css'
})
export class WebUserComponent implements OnInit {
  private ws = WebService.getInstance();
  protected qrCode: string;
  protected loggedIn: boolean = false;
  protected edit: boolean = false;

  constructor(private router: Router) {}

  public ngOnInit(): void {
    this.resetUserData();
  }

  private resetUserData() {
    const payload = this.ws.getTokenPayload();
    this.loggedIn = payload != null;
    if (this.loggedIn) {
      this.qrCode = payload["email"];
      this.email = payload["email"];
      this.name = payload["name"];
      this.lastname = payload["lastname"];
      this.phone = payload["phone"];
    }
  }

  protected email: string;
  
  protected name: string = '';
  private nameRegex = /.{3,25}/;
  protected nameValid(): boolean {
    return (this.name.trim().match(this.nameRegex)) ? true : false;
  };

  protected lastname: string = '';
  private lastnameRegex = /.{3,50}/;
  protected lastnameValid(): boolean {
    return (this.lastname.trim().match(this.lastnameRegex)) ? true : false;
  };

  protected prefixRegex = /^[34]\d+$/; // European prefix only
  protected phone: string = '+34';     // Default to Spain prefix
  protected phoneError: boolean = false;
  protected validLengths: string = '';
  protected phoneValid(): boolean {
    return !this.phoneError;
  };

  protected isUserDataValid(): boolean {
    return this.nameValid() && this.lastnameValid() && this.phoneValid();
  }
  
  protected toggleEdit() {
    if (this.edit == true && this.isUserDataValid()) {
      const phone = this.phone.replace(/[^\+\d]/g, '');
      this.ws.updateUser(this.name.trim(), this.lastname.trim(), phone, 
        () => DialogComponent.showDialog(this.getTranslation('USER_US_UPDATED_TITLE'), this.getTranslation('USER_US_UPDATED_MSG')).then(_ => this.resetUserData()),
        () => DialogComponent.showError(this.getTranslation('ERR_UNKNOWN')).then(_ => this.resetUserData()));
    }
    this.edit = !this.edit;
  }

  protected logout() {
    DialogComponent.showDialog(this.getTranslation('USER_US_EXIT_TITLE'), this.getTranslation('USER_US_EXIT_MSG'))
      .then(_ => { this.ws.clearToken(); this.router.navigate(['']); });
  }

  protected deleteAccount() {
    let inputs = [];
    inputs.push(new DialogOption(this.getTranslation('USER_US_DELETE_CONFIRM_OPTION'), [new OptionValue(null, this.getTranslation('USER_US_DELETE_CONFIRM_NO')), new OptionValue(1, this.getTranslation('USER_US_DELETE_CONFIRM_YES'))]));
    DialogComponent.showInputsDialog(this.getTranslation('USER_US_DELETE_TITLE'), this.getTranslation('USER_US_DELETE_MSG'), inputs, "warning")
      .then(_ => this.ws.deleteAccount(
        () => DialogComponent.showDialog(this.getTranslation('USER_US_DELETED_TITLE'), this.getTranslation('USER_US_DELETED_MSG'))
          .then(_ => { this.ws.clearToken(); this.router.navigate(['']); }),
        () => DialogComponent.showError(this.getTranslation('USER_US_DELETE_ERROR'))
      ));
  }

  protected getTranslation(tag: string, params: any = {}): string {
    return this.ws.translate(tag, params);
  }
}
