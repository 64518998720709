export enum DateFormat {
  Short,
  Medium,
  MonthDay,
  WeekDay,
  Long
}

export class DateUtils {

  private static dayBegin = 6;

  static getNow(): number {
    const today = new Date();
    let now = today.getHours() + today.getMinutes() / 60;
    return now < DateUtils.dayBegin ? now + 24 : now;
  }

  static getNowString(): string {
    return DateUtils.formatTime(DateUtils.getNow());
  }

  static parseTime(time: string): number {
    const parts = time.trim().split(':');
    let result = parseInt(parts[0]) + parseInt(parts[1])/60;
    result += result < DateUtils.dayBegin ? 24 : 0;  // Move night hours to _tomorrow_
    return result;
  }

  static formatTime(time: number): string {
    time = time % 24;
    const hours = Math.floor(time + Number.EPSILON);
    const minutes = Math.round((time - hours) * 60);
    return (hours < 10 ? '0' : '') + hours + ':' + (minutes < 10 ? '0' : '') + minutes;
  }
  
  static formatDuration(duration: number): string {
    let sign = '';
    if (duration < 0) {
      duration = -duration;
      sign = '-';
    }
    const hours = Math.floor(duration + Number.EPSILON);
    const minutes = Math.round((duration - hours) * 60);
    return sign + hours + 'h' + (minutes < 10 ? '0' : '') + minutes + '\'';
  }

  static getTodayDate(): Date {
    let today = new Date();
    today.setHours(today.getHours() - DateUtils.dayBegin);
    today.setHours(0, 0, 0, 0);
    return today;
  }

  static getTodayString(): string {
    return DateUtils.date2IsoString(DateUtils.getTodayDate());
  }

  static isToday(date: string): boolean {
    return date == this.getTodayString();
  }

  static isTomorrow(date: string): boolean {
    let tomorrow = this.getTodayDate();
    tomorrow.setDate(tomorrow.getDate() + 1);
    return this.date2IsoString(tomorrow) == date;
  }

  static date2IsoString(date: Date): string {
    return date.toLocaleDateString('sv').substring(0, 10)
  }

  static date2LocalString(date: Date, mode: DateFormat = DateFormat.Short): string {
    switch (mode) {
    case DateFormat.Long:
      return date.toLocaleDateString(undefined, {weekday: 'long', year: 'numeric', month: 'long', day: 'numeric'});
    case DateFormat.Medium:
      return date.toLocaleDateString(undefined, {month: 'long', day: 'numeric'}) + ', ' + date.toLocaleDateString(undefined, {weekday: 'long'});
    case DateFormat.MonthDay:
      return date.toLocaleDateString(undefined, {month: 'short', day: 'numeric'});
    case DateFormat.WeekDay:
      return date.toLocaleDateString(undefined, {weekday: 'long'});    
    case DateFormat.Short:
    default:
      return date.toLocaleDateString();
    }
  }

  static parseMysqlDate(dateTime: string): Date {
    const parts = dateTime.split(/[- :]/);
    if (parts.length >= 6) {
      return new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2], +parts[3], +parts[4], +parts[5])); // our Date object
    }
    return new Date(Date.UTC(+parts[0], +parts[1] - 1, +parts[2]));
  }

}