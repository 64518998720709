
export class Zone {
  private _id: number;
  private _name: string;
  private _desc: string;
  private _icon: string;
  private _priceType: number;
  private _requirements: any;

  constructor(json: any[]) {
    this._id = +json['id'];
    this._name = json['name'];
    this._desc = json['description'];
    this._icon = json['icon'];
    this._priceType = +json['price_type'];
    try {
      this._requirements = JSON.parse(json['requirements']);
    }
    catch (_) {
      this._requirements = null;
    }
  }

  get id(): number {
    return this._id;
  }

  get name(): string {
    return this._name;
  }

  get desc(): string {
    return this._desc;
  }

  get icon(): string {
    return this._icon;
  }

  get priceType(): number {
    return this._priceType;
  }

  get requirements(): any {
    return this._requirements;
  }
}

export class ZoneMap extends Map<number, Zone> {};
