import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { QRCodeModule } from 'angularx-qrcode';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';

import { ReserveLayout } from './layout-reserve/reserve.layout';
import { UserLayout } from './layout-user/user.layout';

import { WebLoginComponent } from './web-login/web-login.component';
import { WebReserveAddComponent } from './web-reserve-add/web-reserve-add.component';
import { WebReserveCardComponent } from './web-reserve-card/web-reserve-card.component';
import { WebReservesComponent } from './web-reserves/web-reserves.component';
import { WebUserComponent } from './web-user/web-user.component';
import { WebVouchersComponent } from './web-vouchers/web-vouchers.component';

@NgModule({
  declarations: [
    AppComponent,
    ReserveLayout,
    UserLayout,
    WebLoginComponent,
    WebReserveAddComponent,
    WebReserveCardComponent,
    WebReservesComponent,
    WebUserComponent,
    WebVouchersComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    SharedModule,
    QRCodeModule,
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
