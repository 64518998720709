import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { DateUtils, DateFormat } from '../utils/dateUtils';

@Pipe({
  name: 'roster'
})
export class RosterPipe implements PipeTransform {
  transform(value: number): any {
    return value > 0 ? Math.round(value).toString() : '';
  }
}

@Pipe({
  name: 'multiplier'
})
export class MultiplierPipe implements PipeTransform {
  transform(value: number): any {
    return value > 1 ? 'x' + Math.round(value).toString() : '';
  }
}

@Pipe({
  name: 'integer'
})
export class IntegerPipe implements PipeTransform {
  transform(value: number): any {
    return Math.round(value).toString();
  }
}

@Pipe({
  name: 'percent'
})
export class PercentPipe implements PipeTransform {
  transform(value: number): any {
    return Math.round(value).toString() + '\u00A0%'; // u00A0 is unicode for &nbsp;
  }
}

@Pipe({
  name: 'euro'
})
export class EuroPipe implements PipeTransform {
  transform(value: number): any {
    return value.toFixed(2).toString() + '\u00A0€'; // u00A0 is unicode for &nbsp;
  }
}

@Pipe({
  name: 'time'
})
export class TimePipe implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor(value / 60) % 60;
    return (hours > 0 ? (hours + 'h ') : '') + (hours > 0 && minutes < 10 ?  '0' : '') + minutes + 'm';
  }
}

@Pipe({
  name: 'timeofday'
})
export class TimeOfDayPipe implements PipeTransform {
  transform(value: number): string {
    const hours = Math.floor(value / 3600) % 24;
    const minutes = Math.floor(value / 60) % 60;
    return hours + ':' + (minutes < 10 ?  '0' : '') + minutes;
  }
}

@Pipe({
  name: 'closedate'
})
export class CloseDatePipe implements PipeTransform {
  transform(date: string): string {
    let today = DateUtils.getTodayDate();
    if (DateUtils.isToday(date)) {
      return "Hoy";     // TODO: Localize this.
    }
    if (DateUtils.isTomorrow(date)) {
      return "Mañana";
    }
    let todayWeek = DateUtils.getTodayDate();
    todayWeek.setDate(todayWeek.getDate() - (todayWeek.getDay()+6)%7); // TODO: find a way to do this based on locale start of week
    let targetWeek = DateUtils.parseMysqlDate(date);
    targetWeek.setDate(targetWeek.getDate() - (targetWeek.getDay()+6)%7);
    let format = DateFormat.Short;
    if (todayWeek.getDate() == targetWeek.getDate()) {
    format = DateFormat.WeekDay;
    }
    else if (todayWeek.getFullYear() == targetWeek.getFullYear()) {
    format = DateFormat.MonthDay;
    }
    return DateUtils.date2LocalString(DateUtils.parseMysqlDate(date), format);
  }
}


@Pipe({
  name: 'countdown'
})
export class CountdownPipe implements PipeTransform {
  transform(value: number): string {
    value = Math.max(value, 0);
    const hours = Math.floor(value / 3600);
    const minutes = Math.floor(value / 60) % 60;
    const seconds = Math.floor(value % 60);
    return (hours > 0 ? (hours + ':') : '') + (minutes < 10 ?  '0' : '') + minutes + ':' + (seconds < 10 ?  '0' : '') + seconds;
  }
}

@Pipe({
  name: 'elapsed'
})
export class ElapsedPipe implements PipeTransform {
  transform(value: number): string {
    const elapsed = (Date.now() / 1000) - value;
    const hours = Math.floor(elapsed / 3600);
    const minutes = Math.floor(elapsed / 60) % 60;
    return (hours > 0 ? (hours + 'h ') : '') + (hours > 0 && minutes < 10 ?  '0' : '') + minutes + 'm';
  }
}

@Pipe({
  name: "safeHtml",
})
export class SafeHtmlPipe {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html) {
    return this.sanitizer.bypassSecurityTrustHtml(html);
  }
}
