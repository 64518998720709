import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { ReserveLayout } from './layout-reserve/reserve.layout'
import { UserLayout } from './layout-user/user.layout';

import { WebLoginComponent } from './web-login/web-login.component';

const appRoutes: Routes = [
  { path: '', pathMatch: 'full', component: WebLoginComponent },
  { path: 'reserve', pathMatch: 'full', component: ReserveLayout },
  { path: 'reserve/:command/:code', pathMatch: 'full', component: ReserveLayout },
  { path: 'user', pathMatch: 'full', component: UserLayout },
  { path: 'user/register/:code', pathMatch: 'full', component: WebLoginComponent },
]

@NgModule({
  imports: [RouterModule.forRoot(appRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
