import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { WebService } from '../web.service';

enum Mode {
  User,
  Reserves,
  Vouchers,
}

@Component({
  selector: 'app-user-layout',
  templateUrl: './user.layout.html',
  styleUrl: './user.layout.css'
})

export class UserLayout implements OnInit, OnDestroy {
  private ws = WebService.getInstance();
  private subscriptions: Subscription[] = [];
  protected loggedIn: boolean = false;
  protected Mode = Mode;
  protected mode: Mode = Mode.User;
  protected code: string;

  constructor(private route: ActivatedRoute) {}
  
  public ngOnInit(): void {
    this.subscriptions.push(this.ws.subscribeToToken(t => this.tokenUpdated(t)));
     // url param access
     this.route.queryParams.subscribe(
      params => { 
        const keys = Object.keys(params);
        if (keys.length == 1) {
          this.code = keys[0];
        }
      }
    );
    // url path access
    this.code = this.route.snapshot.paramMap.get('code');
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
  }

  private tokenUpdated(t: any[]) {
    this.loggedIn = t != null;
  }

  protected getTranslation(tag: string): string {
    return this.ws.translate(tag);
  }
}

