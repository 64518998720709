<div class="data">
@if (warning) {
  <span class="warning">{{ expanded === false ? '!' : warning }}</span>
}
  <span class="date">{{formatDate(reserve.date)}}</span>
  <span class="times">de {{formatTime(reserve.start) + (reserve.start >= 24 ? getTranslation('USER_RC_NEXT_DAY') : '')}} a {{formatTime(reserve.end) + (reserve.end >= 24 ? getTranslation('USER_RC_NEXT_DAY') : '')}} ({{formatDuration(reserve.duration)}})</span>
  <span class="pax">{{reserve.size + getTranslation('USER_RC_PAX') + (reserve.kids ? getTranslation('USER_RC_KIDS') : '')}}</span>
@if (expanded != null) {
  <lbt-button class="expand outlined" (click)="onClickInternal($event)">▼</lbt-button>
}  
</div>

@if (expanded !== false) {
@if (reserve.kitchen1 || reserve.kitchen2) {
<div>
  <span class="min">Consumo mínimo {{ reserve.getMinTotal() | euro }} ({{ reserve.getMinTotal() / reserve.size | euro }} por persona).</span>
  <span class="lunch">{{ (reserve.kitchen1 ? getTranslation('USER_RC_LUNCH') + reserve.kitchen1 + 'h' : '') }}</span>
  <span class="dinner">{{ (reserve.kitchen2 ? getTranslation('USER_RC_DINNER') + reserve.kitchen2 + 'h' : '') }}</span>
</div>
}
@if (reserve.comments) {
<div>
  <span class="comments">{{getTranslation('USER_RC_COMMENTS') + reserve.comments}}</span>
</div>
}
@if (!reserve.kitchen1 && !reserve.kitchen2 && !reserve.comments && !canUpdate) {
  <div>{{getTranslation('USER_RC_NO_DETAILS')}}</div>
}
@if (canUpdate == true) {
<div class="buttons">
  <lbt-button class="outlined" [disabled]="!isFutureDate" (click)="update()">{{getTranslation('USER_RC_UPDATE')}}</lbt-button>
  <lbt-button class="secondary outlined" (click)="cancel()">{{getTranslation('USER_RC_CANCEL')}}</lbt-button>
</div>
}
}